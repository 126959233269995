// Content Area api
export const publicationDashboardApi = '/publication-management/dashboard/index'
// Content Area api
export const contentAreaListApi = '/publication-management/configuration/content-area/list'
export const contentAreaStoreApi = '/publication-management/configuration/content-area/store'
export const contentAreaUpdateApi = '/publication-management/configuration/content-area/update'
export const contentAreaToggleStatusApi = '/publication-management/configuration/content-area/toggle-status'

// Master Publication Type api
export const publicationTypeList = '/publication-management/configuration/publication-type/list'
export const publicationTypeStoreApi = '/publication-management/configuration/publication-type/store'
export const publicationTypeUpdateApi = '/publication-management/configuration/publication-type/update'
export const publicationTypeToggleStatus = '/publication-management/configuration/publication-type/toggle-status'

// Master Publication Fee api
export const publicationFeeListApi = '/publication-management/configuration/publication-fee/list'
export const publicationFeeStoreApi = '/publication-management/configuration/publication-fee/store'
export const publicationFeeUpdateApi = '/publication-management/configuration/publication-fee/update'
export const publicationFeeToggleStatus = '/publication-management/configuration/publication-fee/toggle-status'

// Master Publication title api
export const publicationTitleListApi = '/publication-management/configuration/publication-title/list'
export const publicationTitleStoreApi = '/publication-management/configuration/publication-title/store'
export const publicationTitleUpdateApi = '/publication-management/configuration/publication-title/update'
export const publicationTitleToggleStatus = '/publication-management/configuration/publication-title/toggle-status'

// content-review and sorting api
export const reviewAndSortingListApi = '/publication-management/content-review-and-sorting/list'
export const publicationProposalUpdateApi = '/research-panel/publication-proposal-submission/update'
export const changeStatusApi = '/publication-management/content-review-and-sorting/change-status'

// circular api
export const circularListApi = '/publication-management/operational-admin-panel/circular-publication/list'
export const circularStoreApi = '/publication-management/operational-admin-panel/circular-publication/store'
export const circularToggleStatus = '/publication-management/operational-admin-panel/circular-publication/toggle-status'

// advertisement-content api
export const adsContentListApi = '/publication-management/operational-admin-panel/advertisement-content/list'
export const adsContentStoreApi = '/publication-management/operational-admin-panel/advertisement-content/store'
export const adsContentToggleStatus = '/publication-management/operational-admin-panel/advertisement-content/toggle-status'
// subscriber-list api
export const subscriberListApi = '/publication-management/publication-subscriber/list'

// publication approval
export const proposalSubmissionListApi = '/research-panel/publication-proposal-submission/submission-list'

// Master Publication title api
export const agenciesStakeholderEnlistmentListApi = '/publication-management/agencies-stakeholder-enlistment/list'
export const agenciesStakeholderEnlistmentStoreApi = '/publication-management/agencies-stakeholder-enlistment/store'
export const agenciesStakeholderEnlistmentUpdateApi = '/publication-management/agencies-stakeholder-enlistment/update'
export const agenciesStakeholderEnlistmentToggleStatus = '/publication-management/agencies-stakeholder-enlistment/toggle-status'
// honorariam
export const honorariamStoreApi = '/publication-management/operational-admin-panel/honorariam/store'

// publication upload api
export const publicationUploadListApi = '/publication-management/publication-upload/list'
export const publicationUploadStoreApi = '/publication-management/publication-upload/store'
export const publicationUploadUpdateApi = '/publication-management/publication-upload/update'
export const publicationUploadToggleStatus = '/publication-management/publication-upload/toggle-status'
export const publishArchiveApi = '/publication-management/publication-upload/publish-archive'
// honorariam report
export const honorariamReportApi = '/publication-management/reports/honorariam/list'

// subscriber-report api
export const subscriberReportListApi = '/publication-management/reports/subscriber-report/list'

// agency-stakeholder-report
export const agencyStakeholderReportListApi = '/publication-management/reports/agency-stakeholder-report/list'

// publication-report
export const publicationReportListApi = '/publication-management/reports/publication-report/list'

// Nothi api
export const nothi = 'nothi/'
export const pendingNothiPublicationApi = nothi + 'publication-sent-nothi'
export const reSentNothiPublicationApi = nothi + 'publication-re-sent-nothi'

// BADC Org region list
export const regionBadcList = '/publication-management/configuration/master-region-modify/list'
export const regionBadcStore = '/publication-management/configuration/master-region-modify/store'
export const regionBadcUpdate = '/publication-management/configuration/master-region-modify/update'
