<template>
    <div class="container-fluid">
    <iq-card class="mt-2">
       <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('publication_management.pub_dashboard') }}</h4>
        </template>
    </iq-card>
        <b-overlay :show="loading">
            <div class="row">
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_circular') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-bullhorn" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalCircular, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_subscriber') }}</div>
                        </div>
                            <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-user-tie" style="font-size:70px;"></i></div>
                        <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalSubscriber, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_agencies') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-building" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalAgency, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_received') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fab fa-buffer" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalReceive, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_approved') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="fas fa-vote-yea" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalApprove, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
                <div class="col-md-4 mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-success text-white row w-100 m-auto">
                            <div class="col-12 p-0" style="font-size:14px;">{{ $t('publication_management.total_reject') }}</div>
                        </div>
                        <div class="card-body row mt-4 mb-4">
                            <div class="col-6 text-center"><i class="far fa-window-close" style="font-size:70px;"></i></div>
                            <div class="col-6" style="font-size:40px;text-align: center;">{{$n(totalRejection, { useGrouping: false })}}</div>
                        </div>
                    </div><!--/.card-->
                </div>
            </div><!-- /.row -->
        </b-overlay>
    </div>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { publicationDashboardApi } from '../../api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      loading: false,
      totalCircular: 0,
      totalSubscriber: 0,
      totalAgency: 0,
      totalReceive: 0,
      totalApprove: 0,
      totalRejection: 0
    }
  },
  computed: {
  },
  async created () {
    this.loadData()
  },
  methods: {
    series () {
      return this.seriesData
    },
    loadData () {
        this.loading = true
        RestApi.getData(agriResearchServiceBaseUrl, publicationDashboardApi).then(response => {
            if (response.success) {
                this.totalCircular = response.data.circular_list
                this.totalSubscriber = response.data.subscriber_list
                this.totalAgency = response.data.agency_list

                this.totalReceive = response.data.receive_list
                this.totalApprove = response.data.approve_list
                this.totalRejection = response.data.reject_list
            }
            this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.bg-delivery {
    background-color: #008082;
}
.hrclass {
    border-top: 1px solid rgb(96, 149, 19) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 0px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    margin: 0 auto;
    margin-top: -20px;
    font-size: 12px !important;
    height: 40px;
    padding-top: 7px;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
